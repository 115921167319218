<template>
  <div class="hello">
    <div class="hello__wrapper">
      <PerlinNoise class="hello__bg"></PerlinNoise>
      <div class="hello__content">
        <div style="position: relative">
          <h5 class="hello__title">NOOL STUDIO</h5>
          <div class="hello__text-wrapper">
            <p class="hello__text">
              We are here to
              <span id="helloTypewriter" class="hello__typewriter">XD</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PerlinNoise from '@/components/PerlinNoise.vue'
import Typewriter from 'typewriter-effect/dist/core'

export default {
  name: 'Hello',
  components: {
    PerlinNoise
  },
  data: function () {
    return {}
  },
  mounted () {
    // eslint-disable-next-line no-new
    new Typewriter('#helloTypewriter', {
      strings: ['help you get digital', 'create awesome apps for you', ''],
      autoStart: true,
      loop: true,
      delay: 80
    })
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/_mixins";
.hello {
  background: black;
  @include fillSize;

  &__wrapper {
    @include fillSize;
    position: relative;
  }

  &__bg {
    position: absolute;
    opacity: 0.35;
  }

  &__content {
    @include fillSize;
    @include centeredFlex;
    height: 95%;
    position: absolute;
    color: white;
    text-align: center;
  }

  &__title {
    font-size: 4em;
    letter-spacing: 0.25em;
    font-weight: 300;
    margin: 0;
  }

  &__text-wrapper {
    position: absolute;
    width: 100%;
  }

  &__text {
    font-size: 1.6em;
    font-weight: 300;
  }

  &__typewriter {
    font-weight: 400;
  }
}

@media only screen and (max-width: 400px) {
  .hello {
    &__title {
      font-size: 3em;
    }
  }
}
</style>
