<template>
  <div class="perlin" id="perlinContainer"></div>
</template>

<script>
import * as Three from 'three'
import fragmentShader from '../assets/webgl/fragment.vert'
import vertexShader from '../assets/webgl/vertex.vert'

let camera = null
let scene = null
let renderer = null
let container = null
let primitive = null
let mat = null
const start = Date.now()

const PrimitiveElement = function () {
  this.mesh = new Three.Object3D()
  mat = new Three.ShaderMaterial({
    wireframe: true,
    uniforms: {
      time: {
        type: 'f',
        value: 0.0
      },
      pointscale: {
        type: 'f',
        value: 0.0
      },
      decay: {
        type: 'f',
        value: 0.0
      },
      complex: {
        type: 'f',
        value: 0.0
      },
      waves: {
        type: 'f',
        value: 0.0
      },
      eqcolor: {
        type: 'f',
        value: 0.0
      },
      fragment: {
        type: 'i',
        value: true
      },
      redhell: {
        type: 'i',
        value: true
      }
    },
    vertexShader,
    fragmentShader
  })
  var geo = new Three.IcosahedronBufferGeometry(5, 50)
  var mesh = new Three.Points(geo, mat)

  this.mesh.add(mesh)
}

const theme = { _darkred: 0x000000 }
const options = {
  perlin: {
    vel: 0.002,
    speed: 0.0002,
    perlins: 2.0,
    decay: 0.2,
    complex: 0.2,
    waves: 25.0,
    eqcolor: 15.0,
    fragment: true,
    redhell: true
  },
  spin: {
    sinVel: 0.0,
    ampVel: 80.0
  }
}

export default {
  name: 'PerlinNoise',
  data () {
    return {

    }
  },
  methods: {
    init: function () {
      this.createWorld()
      this.createPrimitive()
      this.animate()
      window.addEventListener('resize', this.windowResized, false)
    },

    createWorld: function () {
      container = document.getElementById('perlinContainer')
      scene = new Three.Scene()
      scene.background = new Three.Color(theme._darkred)
      camera = new Three.PerspectiveCamera(55, container.clientWidth / container.clientHeight, 1, 1000)
      camera.position.z = 20
      renderer = new Three.WebGLRenderer({ antialias: true, alpha: false })
      renderer.setSize(container.clientWidth, container.clientHeight)
      container.appendChild(renderer.domElement)
    },

    createPrimitive: function () {
      primitive = new PrimitiveElement()
      scene.add(primitive.mesh)
    },

    windowResized: function () {
      container = document.getElementById('perlinContainer')
      renderer.setSize(container.clientWidth, container.clientHeight)
      camera.aspect = container.clientWidth / container.clientHeight
      camera.updateProjectionMatrix()
    },

    animate: function () {
      requestAnimationFrame(this.animate)
      const performance = Date.now() * 0.003
      primitive.mesh.rotation.y += options.perlin.vel
      primitive.mesh.rotation.x = (Math.sin(performance * options.spin.sinVel) * options.spin.ampVel) * Math.PI / 180
      mat.uniforms.time.value = options.perlin.speed * (Date.now() - start)
      mat.uniforms.pointscale.value = options.perlin.perlins
      mat.uniforms.decay.value = options.perlin.decay
      mat.uniforms.complex.value = options.perlin.complex
      mat.uniforms.waves.value = options.perlin.waves
      mat.uniforms.eqcolor.value = options.perlin.eqcolor
      mat.uniforms.fragment.value = options.perlin.fragment
      mat.uniforms.redhell.value = options.perlin.redhell
      camera.lookAt(scene.position)
      renderer.render(scene, camera)
    }
  },

  mounted () {
    this.init()
  },

  unmounted () {
    window.removeEventListener('resize', this.windowResized, false)
  }
}
</script>

<style lang='scss' scoped>
@import "@/scss/_mixins";
.perlin {
  @include fillSize;
}
</style>
