<template>
  <div class="contact">
    <div class="contact__content">
      <h5 class="contact__title">CONTACT US</h5>
      <div class="contact__buttons">
        <a target="_blank" href="https://www.facebook.com/Nool-Studio-106393501184637">
          <div class="contact__button">
            Faceb<span class="contact__bold">oo</span>k
          </div>
        </a>
        <!-- <div class="contact__button">
          <span class="contact__bold">L</span>i<span class="contact__bold"
            >n</span
          >kedi<span class="contact__bold">n</span>
        </div> -->
        <a href="mailto:hello@noolstudio.pl">
          <div class="contact__button">
            Mai<span class="contact__bold">l</span>
          </div>
        </a>
        <a href="tel:668887767">
          <div class="contact__button">
            Ph<span class="contact__bold">on</span>e
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Contact',
  data: function () {
    return {}
  }
}
</script>

<style lang="scss" scoped>
@import "../../scss/_mixins.scss";
.contact {
  @include centeredFlex;
  width: 100vw;
  height: 100vh;
  background-color: black;

  &__content {
    text-align: center;
  }

  &__title {
    color: white;
    font-size: 4em;
    letter-spacing: 0.25em;
    font-weight: 300;
    margin: 0 0 18px 0;
  }

  &__buttons {
    display: flex;
    justify-content: center;
  }

  &__button {
    margin: 0 18px;
    text-transform: uppercase;
    color: white;
    font-weight: 300;
    cursor: pointer;
    font-size: 1.1em;
  }

  &__bold {
    font-weight: 400;
  }
}

@media only screen and (max-width: 400px) {
  .contact {
    &__title {
      font-size: 3em;
    }
  }
}
</style>
