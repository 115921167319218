<template>
  <div class="team">
    <div class="team__title">
      <h1>OUR TEAM</h1>
    </div>
    <div class="team__content">
      <Carousel :settings="{ itemsToShow: 1, snapAlign: 'center' }" :breakpoints="breakpoints">
        <slide v-for="el in people" :key="el.photo">
          <Person :photo="el.photo" :name="el.name" :text="el.text"></Person>
        </slide>
        <template #addons>
          <navigation />
        </template>
      </Carousel>
    </div>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'
import Person from '@/components/Person'

export default {
  name: 'Team',
  data: function () {
    return {
      people: [{
        photo: 'm_sielski',
        name: 'Michał',
        text: 'Project Manager'
      }, {
        photo: 'm_tomczyk',
        name: 'Maksymilian',
        text: 'Head of Development'
      }, {
        photo: 's_wrobel',
        name: 'Szymon',
        text: 'Android Developer'
      }, {
        photo: 'a_kowalczyk',
        name: 'Andrzej',
        text: 'Backend Developer'
      }],
      breakpoints: {
        580: {
          itemsToShow: 2
        },
        850: {
          itemsToShow: 3
        },
        1100: {
          itemsToShow: 4
        }
      }
    }
  },
  components: {
    Carousel,
    Slide,
    Navigation,
    Person
  }
}
</script>

<style lang="scss" scoped>
@import "../../scss/_mixins";
.team {
  width: 100vw;
  height: 100vh;
  position: relative;

  &__content {
    @include centeredFlex;
    @include fillSize;
  }

  &__carousel {
    height: 100%;
  }

  &__title {
    position: absolute;
    top: 80px;
    color: white;
    text-align: center;
    width: 100%;

    & > h1 {
      font-weight: 300;
      margin: 0;
    }
  }
}
</style>

<style>
.carousel {
  width: 80%;
  margin: 0 auto;
}
.carousel__prev,
.carousel__next {
  background-color: transparent;
  margin: 0 -20px;
}
</style>
