<template>
  <div class="home">
    <div class="home__topbar-wrapper">
      <div class="home__topbar">
        <div class="home__topbar-element" @click="scrollTo('#hello')">
          Hello
        </div>
        <div class="home__topbar-element" @click="scrollTo('#team')">Team</div>
        <div class="home__topbar-element" @click="scrollTo('#contact')">
          Contact
        </div>
      </div>
    </div>
    <div class="home__slides">
      <div class="home__slide" id="hello">
        <Hello></Hello>
      </div>
      <div class="home__slide" id="team">
        <Team></Team>
      </div>
      <div class="home__slide" id="contact">
        <Contact></Contact>
      </div>
    </div>
  </div>
</template>

<script>
import Hello from '@/views/main/Hello.vue'
import Team from '@/views/main/Team.vue'
import Contact from '@/views/main/Contact.vue'

export default {
  name: 'Home',
  components: {
    Hello,
    Team,
    Contact
  },
  methods: {
    scrollTo (selector) {
      document.querySelector(selector).scrollIntoView({ behavior: 'smooth' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/_mixins";

.home {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  &__topbar-wrapper {
    position: absolute;
    top: 0px;
    z-index: 2;
    width: calc(100% - 2px);
    padding: 25px 0;
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(6px);
  }

  &__topbar {
    @include centeredFlex;
    text-transform: uppercase;
    margin: 0 auto;
  }

  &__topbar-element {
    color: white;
    margin: 0 20px;
    font-weight: 300;
    cursor: pointer;
  }

  &__slides {
    overflow-y: scroll;
    overflow-x: hidden;
    position: absolute;
    top: 0;
    height: 100vh;
    width: 100vw;
  }

  &__slide {
    width: 100vw;
    height: 100vh;
  }
}
</style>
