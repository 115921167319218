<template>
  <router-view />
</template>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  margin: 0;
  overflow-x: hidden;
  background-color: black;
  color: white;
}

a {
  color: white;
  text-decoration: none;
}

a:visited {
  color: white;
}

a:hover {
  color: white;
}

a:active {
  color: white;
}

::-webkit-scrollbar-track {
  background-color: #000000;
}

::-webkit-scrollbar {
  width: 2px;
  background-color: #000000;
}

::-webkit-scrollbar-thumb {
  background-color: white;
}
</style>
