<template>
  <div class="person">
    <div class="person__image">
      <img :src="getImgUrl(photo)" alt="Person photo" />
    </div>
    <div class="person__name">{{ name }}</div>
    <div class="person__text">{{ text }}</div>
  </div>
</template>

<script>
export default {
  name: 'Person',
  props: {
    photo: String,
    name: String,
    text: String
  },
  data () {
    return {

    }
  },

  methods: {
    getImgUrl (pet) {
      var images = require.context('../assets/team', false, /\.jpg$/)
      return images('./' + pet + '.jpg')
    }
  }
}
</script>

<style lang='scss' scoped>
.person {
  &__image {
    width: 200px;
    height: 200px;
    overflow: hidden;
    margin-bottom: 18px;
    border-radius: 8px;

    & > img {
      width: 100%;
      height: 100%;
    }
  }

  &__name {
    text-transform: uppercase;
    font-weight: 400;
    margin-bottom: 6px;
  }

  &__text {
    font-weight: 300;
  }
}
</style>
